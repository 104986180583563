<template>
  <div class="channel-box">
    <div class="banner2">{{ '汇达频道' | sLang }}</div>
    <div class="channel-content w1200">
      <ul class="news-list-wrap">
        <div class="news-tit">{{ '汇达频道' | sLang }}</div>
        <li class="news-list" v-for="(item, index) in articleContent" :key="index" @click="gotoContent(index)">
          <myImgLazy class="left" :src="require(`@/assets/img/channel/news_list_${index}.jpg`)"></myImgLazy>
          <div class="containt left">
            <div class="con-tit">{{ item[0].val | sLang }}</div>
            <div class="news-time">{{ item[1].val.split('：')[1]| sLang }}</div>
            <div class="news-detail">{{ item[3].val | sLang }}</div>
            <div class="news-more-btn">{{ '查看详情 >>' | sLang }}</div>
          </div>
        </li>
      </ul>

    </div>
    <my-foot @changePage="changePage" id="anchor_3" />
  </div>
</template>

<script>
import '../../assets/css/channel/index.scss'
import myFoot from '@/components/myFoot/index.vue'
import myImgLazy from "@/components/myImgLazy/index.vue"
import articleJs from "@/mixins/article";
export default {
  name: 'channel',
  // data() {
  //   return {
  //     articleActive: -1, // 文章显示内容在mixin articleJs名字
  //   }
  // },
  mixins: [articleJs],
  components: {
    myFoot,
    myImgLazy,
  },
  methods: {
    // 跳转文章详情
    gotoContent(id) {
      this.$router.push({ path: '/article', query: { articleId: id }})
    },
    changePage(i) {
      this.$store.commit('changePage', i)
    },
    
    
  },
}
</script>
